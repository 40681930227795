import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { ExaminationState } from './reducer';
import { AMOUNT_OF_QUESTIONS } from '../../_utils/questionHelpers';

const selectNode = (state: AppState) => state.examination;

export const exampleQuestion = createSelector(selectNode, (state: ExaminationState) => state.exampleQuestion);
export const currentQuestion = createSelector(selectNode, (state: ExaminationState) => state.currentQuestion);
export const isLastQuestion = createSelector(
  selectNode,
  (state: ExaminationState) => state.questions.indexOf(state.currentQuestion) === AMOUNT_OF_QUESTIONS - 1,
);
export const answerForQuestion = (questionId: string) =>
  createSelector(selectNode, (state: ExaminationState) => state.result && state.result[questionId]);
export const currentQuestionIndex = createSelector(selectNode, (state: ExaminationState) =>
  state.questions.indexOf(state.currentQuestion),
);
export const result = createSelector(selectNode, (state: ExaminationState) => state.result);
export const questions = createSelector(selectNode, (state: ExaminationState) => state.questions);
