import { Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { exhaustMap, map, filter, flatMap } from 'rxjs/operators';
import { push } from 'connected-react-router';

import { examinationActions } from '../../_store/actions';
import { ExaminationActionType } from './actions';
import { examinationSelectors } from '../../_store/selectors';
import { RENDER_DELAY } from '../../_utils/questionHelpers';

const selectNextQuestionEpic$: Epic = (action$, state$) =>
  action$.ofType(ExaminationActionType.SelectNextQuestion).pipe(
    flatMap(() => {
      const question = examinationSelectors.currentQuestion(state$.value);

      return from(new Promise(resolve => setTimeout(() => resolve(true), question.time * 1000 * RENDER_DELAY))).pipe(
        filter(() => {
          // Do nothing if question changed
          const currentQuestion = examinationSelectors.currentQuestion(state$.value);
          return currentQuestion === question;
        }),
        map(() => {
          if (examinationSelectors.isLastQuestion(state$.value)) return new examinationActions.ShowResult();
          return new examinationActions.SelectNextQuestion();
        }),
      );
    }),
  );

const showResultEpic$: Epic = action$ => action$.ofType(ExaminationActionType.ShowResult).pipe(map(() => push('/result')));

const startExaminationEpic$: Epic = action$ =>
  action$
    .ofType(ExaminationActionType.StartExamination)
    .pipe(exhaustMap(() => of(new examinationActions.SelectNextQuestion(), push('/examination'))));

const startExampleQuestionEpic$: Epic = action$ =>
  action$.ofType(ExaminationActionType.StartExampleQuestion).pipe(map(() => push('/example-question')));

const showResultQuestion$: Epic = action$ =>
  action$.ofType(ExaminationActionType.ShowResultQuestion).pipe(map(() => push('/result-questions')));

export default [selectNextQuestionEpic$, startExaminationEpic$, showResultQuestion$, showResultEpic$, startExampleQuestionEpic$];
