import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { calculateTime, isKbcBehaviour } from '../../_utils/questionHelpers';
import { useToggle } from '../../_hooks';
import { Question } from '../../examination/_models/Question';
import { authSelectors } from '../../_store/selectors';
import './timer.scss';

type Props = {
  question: Question;
};

const Timer: FC<Props> = ({ question }) => {
  const [value] = useToggle(false);
  const [isTimerStarted, startCounter] = useState<boolean>(value);
  const limitTime = calculateTime(question?.question || '');
  const token = useSelector(authSelectors.token);

  useEffect(() => {
    startCounter(false);
    setTimeout(() => startCounter(true), 300);
  }, [question]);

  return (
    <div
      className={classnames('filler', { 'is-kbc-behaviour': isKbcBehaviour(token), progression: isTimerStarted })}
      style={{ transition: `width ${isTimerStarted ? limitTime : 0}s linear` }}
    />
  );
};

export default Timer;
