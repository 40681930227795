import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';
import { push } from 'connected-react-router';

import * as authApi from './api';
import { AuthActionType } from './actions';
import { authActions } from '../../_store/actions';
import { AccessTokenResponse } from '../_models/AccessTokenResponse';

export const requestAccessTokenEpic$: Epic = action$ =>
  action$.ofType(AuthActionType.RequestAccessToken).pipe(
    exhaustMap(({ payload }: authActions.RequestAccessToken) =>
      from(authApi.requestAccessToken()).pipe(
        map(
          (response: AccessTokenResponse) =>
            new authActions.RequestAccessTokenSuccess({
              accessToken: response.Message,
              body: payload.body,
            }),
        ),
        catchError(error => of(new authActions.RequestAccessTokenError({ error }))),
      ),
    ),
  );

export const requestAccessTokenSuccessEpic$: Epic = action$ =>
  action$.ofType(AuthActionType.RequestAccessTokenSuccess).pipe(
    exhaustMap(({ payload }: authActions.RequestAccessTokenSuccess) =>
      from(authApi.register(payload.body, payload.accessToken)).pipe(
        map(() => new authActions.RegisterSuccess()),
        catchError(error => of(new authActions.RegisterError({ error }))),
      ),
    ),
  );

export const checkEpycTokenEpic$: Epic = action$ =>
  action$.ofType(AuthActionType.CheckEpycToken).pipe(
    exhaustMap(({ payload }: authActions.CheckEpycToken) =>
      from(authApi.checkEpycToken(payload.token)).pipe(
        map(() => push('/')),
        catchError(() => of(push('/login'))),
      ),
    ),
  );

const registerSuccessEpic$: Epic = action$ => action$.ofType(AuthActionType.RegisterSuccess).pipe(map(() => push('/')));

export default [requestAccessTokenEpic$, requestAccessTokenSuccessEpic$, registerSuccessEpic$, checkEpycTokenEpic$];
