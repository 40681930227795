import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import AnswerOption from '../examination/answer-option/AnswerOption';
import { Button, Icon } from '../_shared';
import { translations } from '../_translations';
import { AMOUNT_OF_QUESTIONS, isMajorMistake, AnswerOptionStatus } from '../_utils/questionHelpers';
import { examinationSelectors } from '../_store/selectors';
import { Answer } from '../examination/_models/Answer';
import { examinationActions } from '../_store/actions';
import './resultquestion.scss';

const ResultQuestion: FC = () => {
  const currentQuestion = useSelector(examinationSelectors.currentQuestion);
  const result = useSelector(examinationSelectors.result);
  const isLastQuestion = useSelector(examinationSelectors.isLastQuestion);
  const currentQuestionIndex = useSelector(examinationSelectors.currentQuestionIndex);
  const givenAnswer = result[currentQuestion?.question_id];
  const firstQuestion = currentQuestionIndex === 0;
  const dispatch = useDispatch();

  function answerToStatus(answer: Answer): AnswerOptionStatus {
    if (answer.correct_ind) return AnswerOptionStatus.Correct;
    if (answer.answer_id === givenAnswer?.answer_id && !givenAnswer?.correct_ind) return AnswerOptionStatus.Wrong;
    return AnswerOptionStatus.Unselected;
  }

  if (!currentQuestion) return <Redirect to="/result" />;

  return (
    <div className="question-wrapper result-question">
      <img alt={translations.getLabel('MODAL.IMAGE_ALT')} src={require(`../_assets/media/${currentQuestion.img_url}`)} />
      <div className="answers-wrapper">
        <p className="info">{`${translations.getLabel('QUESTIONS.QUESTION').toUpperCase()} ${currentQuestionIndex +
          1}/${AMOUNT_OF_QUESTIONS}`}</p>
        <h2>{currentQuestion.question}</h2>
        {currentQuestion.answers.map(answer => (
          <AnswerOption answer={answer} key={answer.answer_id} status={answerToStatus(answer)} />
        ))}
        {currentQuestion.explanation && (
          <>
            <h5>
              {translations.getLabel('QUESTIONS.EXPLANATION')}
              {isMajorMistake(currentQuestion) && (
                <span> - {translations.getLabel('QUESTIONS.MAJOR_MISTAKE').toLowerCase()}</span>
              )}
            </h5>
            <p className="explanation">{currentQuestion.explanation}</p>
          </>
        )}
        <div className="buttons-wrapper">
          <Icon
            className="arrow left"
            disabled={firstQuestion}
            name="SvgArrow"
            onClick={() => dispatch(new examinationActions.ShowPreviousResultQuestion())}
          />
          <Button disabled={isLastQuestion} href="/result">
            {translations.getLabel('BUTTONS.BACK_TO_OVERVIEW')}
          </Button>
          <Icon
            className="arrow right"
            disabled={isLastQuestion}
            name="SvgArrow"
            onClick={() => dispatch(new examinationActions.ShowNextResultQuestion())}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultQuestion;
