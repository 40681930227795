import React, { FC } from 'react';

import { Question } from '../../_models/Question';
import { Icon, Button } from '../../../_shared';
import { translations } from '../../../_translations';
import './imgcloseup.scss';

type Props = {
  onClose: () => void;
  question: Question;
};

const ImageCloseUp: FC<Props> = ({ onClose, question }) => {
  return (
    <div className="img-modal">
      <Icon className="close-icon" name="SvgClose" onClick={onClose} />
      <img alt={translations.getLabel('MODAL.IMAGE_ALT')} src={require(`../../../_assets/media/${question.img_url}`)} />
      <Button className="close reversed" onClick={onClose}>
        {translations.getLabel('BUTTONS.CLOSE')}
      </Button>
    </div>
  );
};

export default ImageCloseUp;
