import { HttpClient } from '../../_http';
import { Auth } from '../_models/Auth';
import { AccessTokenResponse } from '../_models/AccessTokenResponse';

export function requestAccessToken(): Promise<AccessTokenResponse> {
  return HttpClient.get<AccessTokenResponse>('auth/gettoken', null);
}

export function register(body: Auth, accessToken: string): Promise<void> {
  return HttpClient.post<void>('proefexamen/register', body, {
    Authorization: `Basic ${accessToken}`,
  });
}

export function checkEpycToken(token: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (token) {
      resolve();
    } else reject();
  });
}
