import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { AuthState } from './reducer';

const selectNode = (state: AppState) => state.auth;

export const token = createSelector(selectNode, (state: AuthState) => state.token);
export const requestError = createSelector(selectNode, (state: AuthState) => state.requestError);
export const isRegistered = createSelector(selectNode, (state: AuthState) => state.isRegistered);
