import React, { FC } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import logo from '../../../_assets/images/logo.svg';
import { Language } from '../../../_translations/Language';
import { isKbcApp } from '../../../_utils/initializeHelper';
import { authSelectors } from '../../../_store/selectors';
import './examinationlayoutMenu.scss';

const ExaminationLayoutMenu: FC = () => {
  const { pathname } = useLocation();
  const isKbc = isKbcApp();
  const isResultQuestion = pathname === '/result-questions';
  const isStartScreen = pathname === '/';
  const isEpyc = useSelector(authSelectors.token);
  const locale = Language.getLocale();

  function canShowImg() {
    if (isKbc && isStartScreen) return true;
    if (isKbc) return false;
    if (isEpyc) return false;
    return true;
  }

  function canShowLanguageSwitch() {
    switch (pathname) {
      case '/':
        return true;
      case '/login':
        return true;
      default:
        return false;
    }
  }

  return (
    <header className={classnames('', { 'is-kbc-behaviour': (isKbc && !isStartScreen) || isEpyc })}>
      {!isResultQuestion && (
        <>
          {canShowImg() && (
            <a
              className={classnames({ 'is-kbc-behaviour': isKbc })}
              href={isKbc ? '/' : 'https://www.vab.be/nl/rijschool'}
              rel="noopener noreferrer"
              target={isKbc ? '_self' : '_blank'}
            >
              <img alt="vab-logo" src={logo} />
            </a>
          )}
          <div className="timer" />
        </>
      )}
      {isKbcApp() && canShowLanguageSwitch() && (
        <div className="language">
          <span className={classnames({ 'is-active': locale === 'fr' })} onClick={() => Language.setLocale('fr')}>
            FR
          </span>
          <span className={classnames({ 'is-active': locale === 'nl' })} onClick={() => Language.setLocale('nl')}>
            | NL
          </span>
        </div>
      )}
    </header>
  );
};

export default ExaminationLayoutMenu;
