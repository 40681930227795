import React, { FC, useEffect } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { PostmessageExternalSender } from './_utils';
import ReduxDelegatedModal from './modal/ReduxDelegatedModal';
import AuthorizedRoute from './_routing/routes/AuthorizedRoute';
import AuthorizedLayout from './_routing/layouts/authorized/AuthorizedLayout';
import UnauthorizedRoute from './_routing/routes/UnauthorizedRoute';
import UnauthorizedLayout from './_routing/layouts/unauthorized/UnauthorizedLayout';
import EpycEntryPoint from './_routing/layouts/EpycEntryPoint';

const App: FC = () => {
  useEffect(() => {
    const postmessageExternalSender = new PostmessageExternalSender();
    postmessageExternalSender.pageLoaded();
    postmessageExternalSender.pageReady();
  }, []);

  return (
    <>
      <Switch>
        <Route component={EpycEntryPoint} path="/epyc/:token?" />
        <UnauthorizedRoute component={UnauthorizedLayout} path="/login" />
        <AuthorizedRoute component={AuthorizedLayout} path="/" />
        <Redirect to="/" />
      </Switch>
      <ReduxDelegatedModal />
    </>
  );
};

export default App;
