import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isKbcApp } from '../../_utils/initializeHelper';
import { authSelectors } from '../../_store/selectors';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  const { pathname } = useLocation();
  const isRegistered = useSelector(authSelectors.isRegistered);
  const token = useSelector(authSelectors.token);
  const isLoggedIn = isKbcApp() || isRegistered || token;

  return (
    <Route
      {...rest}
      render={props => (isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { pathname } }} />)}
    />
  );
};

export default AuthorizedRoute;
