export type Locale = 'fr' | 'nl';
const defaultLocale: Locale = 'nl';

export class Language {
  static setLocale(locale: Locale): void {
    window.localStorage.setItem('locale', locale);
    // Reload the window to make app re-render
    window.location.reload();
  }

  static getLocale(): Locale {
    return (window.localStorage.getItem('locale') as Locale) || defaultLocale;
  }
}
