import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

import { authActions } from '../../_store/actions';

const EpycEntryPoint = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(new authActions.CheckEpycToken({ token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <Loader />;
};

export default EpycEntryPoint;
