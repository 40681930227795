import I18n from 'i18n-js';

import { config } from '../config';
import fr from './fr.json';
import nl from './nl.json';
import { Language } from './Language';

const getLabel = (key: string, inserts = {}): string => {
  if (config.environment() !== 'production' && I18n.t(key, inserts) === '') {
    return `[[${key}]]`;
  }
  return I18n.t(key, inserts);
};

I18n.fallbacks = true;
const locale = Language.getLocale();
I18n.locale = locale === 'fr' ? 'fr' : 'nl';
I18n.missingTranslation = translation => `[[${translation}]]`;

// Make translations object with all the labels
const translations = {
  fr,
  nl,
};

I18n.translations = translations;

export default {
  I18n,
  getLabel,
};
