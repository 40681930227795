import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Prompt, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { translations } from '../../_translations';
import { examinationSelectors, authSelectors } from '../../_store/selectors';
import { Button, Timer } from '../../_shared';
import { useToggle } from '../../_hooks';
import { AMOUNT_OF_QUESTIONS, AnswerOptionStatus, shuffle, isKbcBehaviour } from '../../_utils/questionHelpers';
import { examinationActions } from '../../_store/actions';
import AnswerOption from '../answer-option/AnswerOption';
import StopExaminationModal from '../modal/stopexaminationmodal/StopExaminationModal';
import ImageCloseUp from '../modal/imgcloseup/ImageCloseUp';
import { Answer } from '../_models/Answer';
import Pinch from '../../_shared/pinch-zoom/Pinch';

const Question: FC = () => {
  const [currentAnswers, setCurrentAnswers] = useState<Answer[]>([]);
  const { pathname } = useLocation();
  const questions = useSelector(examinationSelectors.questions) || [];
  const currentQuestion = useSelector(examinationSelectors.currentQuestion);
  const isLastQuestion = useSelector(examinationSelectors.isLastQuestion);
  const currentQuestionIndex = questions.indexOf(currentQuestion);
  const selectedAnswer = useSelector(examinationSelectors.answerForQuestion(currentQuestion?.question_id));
  const token = useSelector(authSelectors.token);
  const [isImageCloseOpen, showImageCloseUp] = useToggle(false);
  const [isStopExaminationOpen, setStopExamination] = useToggle(false);
  const dispatch = useDispatch();
  const shouldShowPrompt = !isStopExaminationOpen && !isLastQuestion && pathname === '/examination';

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentAnswers(shuffle(currentQuestion?.answers));
  }, [currentQuestion, currentQuestionIndex]);

  function goToNextQuestion(): void {
    if (isLastQuestion) dispatch(new examinationActions.ShowResult());
    else dispatch(new examinationActions.SelectNextQuestion());
  }

  function answerToStatus(answer: Answer): AnswerOptionStatus {
    if (!selectedAnswer) return AnswerOptionStatus.Unselected;
    if (selectedAnswer.answer_id === answer.answer_id) return AnswerOptionStatus.Selected;
    return AnswerOptionStatus.Unselected;
  }

  if (!questions.length || !currentQuestion) return <Redirect to="/" />;

  return (
    <>
      {isStopExaminationOpen && <StopExaminationModal closeModal={() => setStopExamination(false)} />}
      {isImageCloseOpen && <ImageCloseUp onClose={() => showImageCloseUp(false)} question={currentQuestion} />}
      <Timer question={currentQuestion} />
      <Prompt message={translations.getLabel('QUESTIONS.PROMPT')} when={shouldShowPrompt} />
      <div className={classnames('question-wrapper', { 'is-kbc-behaviour': isKbcBehaviour(token) })}>
        <Pinch questionId={currentQuestionIndex}>
          <img
            alt={translations.getLabel('MODAL.IMAGE_ALT')}
            onClick={() => showImageCloseUp(true)}
            src={require(`../../_assets/media/${currentQuestion.img_url}`)}
          />
        </Pinch>
        <div className="answers-wrapper">
          <p className="info">{`${translations.getLabel('QUESTIONS.QUESTION').toUpperCase()} ${currentQuestionIndex +
            1}/${AMOUNT_OF_QUESTIONS}`}</p>
          <h2>{currentQuestion.question}</h2>
          {currentAnswers.map(answer => (
            <AnswerOption
              answer={answer}
              key={answer.answer_id}
              onSelect={() =>
                dispatch(
                  new examinationActions.SetAnswer({
                    answer: answer,
                    questionId: currentQuestion?.question_id,
                  }),
                )
              }
              status={answerToStatus(answer)}
            />
          ))}
          <div className="actions-wrapper">
            <Button asText onClick={() => setStopExamination(true)}>
              {translations.getLabel('BUTTONS.STOP')}
            </Button>
            <Button onClick={goToNextQuestion} primary={isLastQuestion}>
              {translations.getLabel(isLastQuestion ? 'BUTTONS.SHOW_RESULTS' : 'BUTTONS.NEXT')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
