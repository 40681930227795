import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer, Action } from 'redux';

import authReducer, { AuthState } from '../auth/_store/reducer';
import examinationReducer, { ExaminationState } from '../examination/_store/reducer';
import modalReducer, { ModalState } from '../modal/_store/reducer';

export interface AppState {
  auth: AuthState;
  examination: ExaminationState;
  modal: ModalState;
  router: RouterState;
}

function appReducer(history: History): Reducer {
  return combineReducers<AppState>({
    auth: authReducer,
    examination: examinationReducer,
    modal: modalReducer,
    router: connectRouter(history),
  });
}

export default (history: History) => (state: AppState, action: Action) => {
  return appReducer(history)(state, action);
};
