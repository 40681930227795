import { useState, useEffect } from 'react';

function useTimer(limitTime: number, onFinish: () => void): number {
  const [count, setCount] = useState<number>(limitTime);

  useEffect(() => {
    let counter = limitTime;
    function handleTimer() {
      if (counter === 1) {
        onFinish();
        return;
      }
      counter--;
      setCount(counter);
      setTimeout(handleTimer, 1000);
    }
    setTimeout(handleTimer, 1000);
    // onFinish can not be a dependancy in this array otherwise the counter will not work
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitTime]);

  return count;
}

export default useTimer;
