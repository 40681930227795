import { Action } from 'redux';

import { Answer } from '../_models/Answer';
import { Question } from '../_models/Question';

export enum ExaminationActionType {
  SelectNextQuestion = '[Examination] SelectNextQuestion',
  SetAnswer = '[Examination] SetAnswer',
  ShowNextResultQuestion = '[Examination] ShowNextResultQuestion',
  ShowPreviousResultQuestion = '[Examination] ShowPreviousResultQuestion',
  ShowResult = '[Examination] ShowResult',
  ShowResultQuestion = '[Examination] ShowResultQuestion',
  StartExamination = '[Examination] StartExamination',
  StartExampleQuestion = '[Examination] StartExampleQuestion',
  StopExamination = '[Examination] StopExamination',
}
export class SelectNextQuestion implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.SelectNextQuestion;
}

export class ShowNextResultQuestion implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.ShowNextResultQuestion;
}

export class ShowPreviousResultQuestion implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.ShowPreviousResultQuestion;
}

export class SetAnswer implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.SetAnswer;
  constructor(public payload: { answer: Answer; questionId: string }) {}
}

export class StartExamination implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.StartExamination;
}

export class StartExampleQuestion implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.StartExampleQuestion;
}

export class ShowResultQuestion implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.ShowResultQuestion;
  constructor(public payload: { question: Question }) {}
}

export class ShowResult implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.ShowResult;
}

export class StopExamination implements Action<ExaminationActionType> {
  readonly type = ExaminationActionType.StopExamination;
}

export type ExaminationAction =
  | SelectNextQuestion
  | ShowNextResultQuestion
  | ShowPreviousResultQuestion
  | SetAnswer
  | StartExamination
  | StartExampleQuestion
  | ShowResultQuestion
  | ShowResult
  | StopExamination;
