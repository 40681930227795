import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translations } from '../_translations';
import { Button, Icon } from '../_shared';
import InputField from '../_shared/inputField/InputField';
import Checkbox from '../_shared/checkbox/Checkbox';
import { useToggle } from '../_hooks';
import { authActions } from '../_store/actions';
import { authSelectors } from '../_store/selectors';
import ErrorMessage from '../_shared/errorMessage/ErrorMessage';
import { Auth } from './_models/Auth';
import { formValidator } from '../_utils/formValidation';
import './login.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [optIn, setOptIn] = useToggle(false);
  const error = useSelector(authSelectors.requestError);

  const dispatch = useDispatch();

  function registerUser() {
    if (!formValidator.email(email).isValid) {
      dispatch(new authActions.SetError({ error: translations.getLabel('LOGIN.ERROR') }));
      return;
    }
    const body: Auth = {
      email,
      optIn,
    };
    dispatch(new authActions.RequestAccessToken({ body }));
  }

  return (
    <div className="login-wrapper">
      <span className="title-wrapper">
        <h1>{translations.getLabel('LOGIN.TITLE_ONE')}</h1>
        <h1>{translations.getLabel('LOGIN.TITLE_TWO')}</h1>
      </span>
      <h3>{translations.getLabel('LOGIN.LOGIN')}</h3>
      <div className="form">
        <InputField
          autoComplete="email"
          className="email"
          name="email"
          onChange={value => setEmail(value)}
          placeholder={translations.getLabel('LOGIN.MAIL')}
          type="email"
          value={email}
        />
        <Checkbox
          checked={optIn}
          className="opt-in"
          label={translations.getLabel('LOGIN.OPTIN')}
          name={translations.getLabel('LOGIN.OPTIN')}
          onChange={() => setOptIn()}
        />
        <ErrorMessage isVisible={!!error}>{translations.getLabel('LOGIN.ERROR')}</ErrorMessage>
        <Button className="start-examination" disabled={!email} onClick={registerUser} primary type="submit">
          <p>{translations.getLabel('BUTTONS.START')}</p>
          <Icon className="chrevron" name="SvgChevronRight" />
        </Button>
      </div>
      <div className="mail-to" dangerouslySetInnerHTML={{ __html: translations.getLabel('LOGIN.QUESTIONS') }} />
    </div>
  );
};

export default Login;
