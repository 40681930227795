import { categories, questions } from '../_assets/data';
import { Answer } from '../examination/_models/Answer';
import { Question } from '../examination/_models/Question';
import { Category } from '../examination/_models/Category';
import { isKbcApp } from './initializeHelper';

const DEFAULT_READING_TIME = 15;
const READING_TIME_PER_WORD = 1.5;
export const AMOUNT_OF_QUESTIONS = 50;
export const CORRECT_AMOUNT_OF_ANSWERS = 42;
export const RENDER_DELAY = 1.025;

export enum AnswerOptionStatus {
  Correct = 'CORRECT',
  Selected = 'SELECTED',
  Unselected = 'UNSELECTED',
  Wrong = 'WRONG',
}

export function calculateTime(sentence: string): number {
  // count how many words the sentence includes
  const wordCount = sentence.split(' ').length;

  // calculate if the sentence is too long for the default time
  const isLongerSentence = wordCount * READING_TIME_PER_WORD > DEFAULT_READING_TIME;

  // if sentence is too long, calculate the extra time the user gets
  if (isLongerSentence) {
    return wordCount * READING_TIME_PER_WORD;
  }
  // return default time
  return DEFAULT_READING_TIME;
}

export function selectExampleQuestion(): Question {
  const question = questions[Math.floor(Math.random() * questions.length)];
  return {
    ...question,
    time: calculateTime(question.question),
  };
}

export const isCorrectAnswer = (question: Question, answer: Answer): boolean => {
  const correctAnswer = question.answers.find(answer => answer.correct_ind);

  return answer.answer_id === correctAnswer.answer_id;
};

export function selectExaminationQuestions(): Question[] {
  return (
    categories
      .map((category: Category) => {
        const maxAmountPerCategory = parseInt(category.limit);

        // filtered questions by category_id and exclude example question
        const filteredQuestions = questions.filter(question => {
          if (question.category_id !== category.category_id) return false;
          if (question.category_id === selectExampleQuestion().category_id) return false;
          return true;
        });

        const addedTimeForQuestion = filteredQuestions.map(question => ({
          ...question,
          time: calculateTime(question.question),
        }));

        // select ‘maxAmountPerCategory’ random questions
        return addedTimeForQuestion
          .sort(function() {
            return 0.5 - Math.random();
          })
          .slice(0, maxAmountPerCategory);
      })
      //flatten array
      .reduce((acc, currentValue) => [...acc, ...currentValue], [])
  );
}

export function selectNextQuestion(addedQuestionId: string | null, questions: Question[]): Question {
  const currentQuestion = questions.find(q => q.question_id === addedQuestionId);
  const currentQuestionId = questions.indexOf(currentQuestion);

  return questions[currentQuestionId + 1];
}

export function amountOfCorrectAnswers(result: Record<string, Answer>): number {
  return Object.values(result).filter(item => item?.correct_ind).length;
}

export function hasPassedExamination(result: Record<string, Answer>): boolean {
  return amountOfCorrectAnswers(result) >= CORRECT_AMOUNT_OF_ANSWERS;
}

export function isMajorMistake(question: Question): boolean {
  return question?.weight_category === 'MajorOffence';
}

export function shuffle(array: Answer[]) {
  return array.sort(() => Math.random() - 0.5);
}

export function isKbcBehaviour(isEpyc: string) {
  if (isKbcApp() || isEpyc) return true;
  return false;
}
