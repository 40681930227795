import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ExaminationLayoutMenu from '../examinationlayout-menu/ExaminationLayoutMenu';
import Login from '../../../auth/Login';
import '../layout.scss';

const UnauthorizedLayout: FC = () => {
  return (
    <>
      <ExaminationLayoutMenu />
      <Switch>
        <Route component={Login} path="/login" />
        <Redirect to="/login" />
      </Switch>
    </>
  );
};

export default UnauthorizedLayout;
