import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Answer } from '../_models/Answer';
import { Icon } from '../../_shared';
import { AnswerOptionStatus } from '../../_utils/questionHelpers';
import './answeroption.scss';

type Props = {
  answer: Answer;
  onSelect?: () => void;
  status?: AnswerOptionStatus;
};

const AnswerOption: FC<Props> = ({ answer, onSelect, status }) => {
  const { pathname } = useLocation();
  const isResultQuestion = pathname === '/result-questions';

  const renderIcon = (): ReactNode => {
    if (status === AnswerOptionStatus.Unselected) return <Icon className="icon icon-unselected" name="SvgAnswerUnselected" />;
    if (status === AnswerOptionStatus.Selected) return <Icon className="icon icon-selected" name="SvgAnswerSelected" />;
    if (status === AnswerOptionStatus.Correct) return <Icon className="icon icon-correct" name="SvgAnswerCorrect" />;
    if (status === AnswerOptionStatus.Wrong) return <Icon className="icon icon-wrong" name="SvgAnswerWrong" />;
  };

  return (
    <div
      className={classnames('answer', {
        correct: status === AnswerOptionStatus.Correct,
        'is-result': isResultQuestion,
        selected: status === AnswerOptionStatus.Selected,
        wrong: status === AnswerOptionStatus.Wrong,
      })}
      key={answer.answer_id}
      onClick={onSelect}
    >
      {renderIcon()}
      <p>{answer.answer}</p>
    </div>
  );
};

export default AnswerOption;
