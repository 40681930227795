import React, { FC } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { PostmessageExternalSender } from '../../../_utils';
import ExaminationLayoutMenu from '../examinationlayout-menu/ExaminationLayoutMenu';
import StartScreen from '../../../startscreen/StartScreen';
import ExampleQuestion from '../../../example-question/ExampleQuestion';
import Question from '../../../examination/question/Question';
import Publicity from '../../../publicity/Publicity';
import Result from '../../../result/Result';
import ResultQuestion from '../../../result-question/ResultQuestion';
import { translations } from '../../../_translations';
import '../layout.scss';
import { isKbcApp, isKbcIos } from '../../../_utils/initializeHelper';

const AuthorizedLayout: FC = () => {
  const { pathname } = useLocation();
  const canNotShowMarketingBanners = pathname !== '/';
  const postmessageExternalSender = new PostmessageExternalSender();

  const openUrl = (url: string): void => {
    postmessageExternalSender.openUrl(url);
  };

  return (
    <div className="authorized-layout">
      <ExaminationLayoutMenu />
      {canNotShowMarketingBanners && (
        <>
          <div className="leaderboard" id="proefexamen_leaderboard">
            <a href="# " rel="noopener noreferrer" target="_blank">
              <img
                alt={translations.getLabel('SHARED.LEADERBOARD')}
                src="https://www.vab.be/-/media/5a6764b97fc84c90b54e24cdfc7f48c4.ashx"
              />
            </a>
          </div>
          <div className="skyscraper left" id="proefexamen_skyscraper_one">
            <a href="# " rel="noopener noreferrer" target="_blank">
              <img
                alt={translations.getLabel('SHARED.SKYSCRAPER')}
                src="https://www.vab.be/-/media/5881b56ba29d430fa800193bb34d0095.ashx"
              />
            </a>
          </div>
          <div className="skyscraper right" id="proefexamen_skyscraper_two">
            <a href="# " rel="noopener noreferrer" target="_blank">
              <img
                alt={translations.getLabel('SHARED.SKYSCRAPER')}
                src="https://www.vab.be/-/media/fb29377d098f4233949019fd33d30a0b.ashx"
              />
            </a>
          </div>
        </>
      )}
      <Switch>
        <Route component={StartScreen} exact path="/" />
        <Route component={ExampleQuestion} path="/example-question" />
        <Route component={Question} path="/examination" />
        <Route component={Publicity} path="/publicity" />
        <Route component={Result} path="/result" />
        <Route component={ResultQuestion} path="/result-questions" />
        <Redirect to="/" />
      </Switch>
      <div className="disclaimer">
        © {new Date().getFullYear()}{' '}
        {isKbcApp() ? (
          <>
            <span onClick={() => openUrl('https://www.vab.be/nl/rijschool')}>VAB Rijschool nv</span>- Alle rechten voorbehouden -
            <span onClick={() => openUrl('https://www.vab.be/nl/over-vab/disclaimer/')}>Disclaimer</span>-
            <span onClick={() => openUrl('https://www.vab.be/nl/over-vab/privacy/')}>Privacy</span>
          </>
        ) : (
          <>
            <a href="https://www.vab.be/nl/rijschool" rel="noopener noreferrer" target="_blank">
              VAB Rijschool nv
            </a>{' '}
            - Alle rechten voorbehouden -{' '}
            <a href="https://www.vab.be/nl/over-vab/disclaimer/" rel="noopener noreferrer" target="_blank">
              Disclaimer
            </a>{' '}
            -
            <a href="https://www.vab.be/nl/over-vab/privacy/" rel="noopener noreferrer" target="_blank">
              {' '}
              Privacy
            </a>{' '}
          </>
        )}
        -{' '}
        {isKbcIos() ? (
          <span onClick={() => postmessageExternalSender.sendEmail('proefexamen@vabrijschool.be')}>Contact</span>
        ) : (
          <a href="mailto: proefexamen@vabrijschool.be" rel="noopener noreferrer" target="_blank">
            Contact
          </a>
        )}
      </div>
    </div>
  );
};

export default AuthorizedLayout;
