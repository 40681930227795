const tabletBreakpoint = 414;
const desktopBreakpoint = 1024;
const monitorBreakpoint = 1200;

export function isTabletSize(width: number): boolean {
  return width > tabletBreakpoint;
}

export function isDesktopSize(width: number): boolean {
  return width > desktopBreakpoint;
}

export function isMonitorSize(width: number): boolean {
  return width > monitorBreakpoint;
}
