import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isKbcApp } from '../../_utils/initializeHelper';

const UnauthorizedRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={props => (!isKbcApp() ? <Component {...props} /> : <Redirect to="/" />)} />;
};

export default UnauthorizedRoute;
