import React from 'react';
import { useDispatch } from 'react-redux';

import { translations } from '../_translations';
import { examinationActions } from '../_store/actions';
import { useTimer } from '../_hooks';
import './countdown.scss';

const Countdown = () => {
  const dispatch = useDispatch();
  const count = useTimer(3, () => dispatch(new examinationActions.StartExamination()));

  return (
    <div className="countdown">
      <p className="title">{translations.getLabel('COUNTDOWN.TITLE')}</p>
      <p className="sentence">{translations.getLabel('COUNTDOWN.SENTENCE')}</p>
      <p className="count">{count}</p>
    </div>
  );
};

export default Countdown;
