import React, { FC, ReactNode } from 'react';
import { Modal as SemanticModal } from 'semantic-ui-react';
import classnames from 'classnames';

import { translations } from '../../_translations';
import Button from '../button/Button';
import './modal.scss';

interface Props {
  children: ReactNode;
  className?: string;
  ctaButtons?: ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  open: boolean;
  trigger?: ReactNode;
}

const Modal: FC<Props> & { Actions; Content; Header } = ({ className, ctaButtons, open, onOpen, onClose, trigger, children }) => (
  <SemanticModal className={classnames('base-modal', className)} onClose={onClose} onOpen={onOpen} open={open} trigger={trigger}>
    {children}
    <div className="cta-wrapper">
      {ctaButtons}
      <Button className="close" href="/">
        {translations.getLabel('BUTTONS.CLOSE')}
      </Button>
    </div>
  </SemanticModal>
);

Modal.defaultProps = {
  className: '',
};

Modal.Header = SemanticModal.Header;
Modal.Content = SemanticModal.Content;
Modal.Actions = SemanticModal.Actions;

export default Modal;
