import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Icon } from '../_shared';
import { translations } from '../_translations';
import { examinationActions } from '../_store/actions';
import './startscreen.scss';

const StartScreen: FC = () => {
  const dispatch = useDispatch();

  return (
    <div className="startscreen-wrapper">
      <div className="literals-wrapper">
        <ul>
          {['ONE', 'TWO', 'THREE', 'FOUR'].map((value: string) => (
            <li key={value}>{translations.getLabel(`START.LITERAL_${value}`)}</li>
          ))}
        </ul>
      </div>
      <Button onClick={() => dispatch(new examinationActions.StartExampleQuestion())} primary>
        <p>{translations.getLabel('BUTTONS.START_EXAM')}</p>
        <Icon className="chrevron" name="SvgChevronRight" />
      </Button>
    </div>
  );
};

export default StartScreen;
