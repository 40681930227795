import { Category } from '../../examination/_models/Category';
import { Question } from '../../examination/_models/Question';
import { categories as _categories_nl, questions as _questions_nl } from './exercises-nl.json';
import { categories as _categories_fr, questions as _questions_fr } from './exercises-fr.json';
import { Language } from '../../_translations/Language';
import { isKbcApp } from '../../_utils/initializeHelper';

function data() {
  if (Language.getLocale() === 'fr' && isKbcApp()) return { cat: _categories_fr, questions: _questions_fr };
  return { cat: _categories_nl, questions: _questions_nl };
}

const categories: Category[] = data().cat;
const questions: Question[] = data().questions as Question[];

export { categories, questions };
