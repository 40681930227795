import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';

import { examinationSelectors, authSelectors } from '../_store/selectors';
import {
  AMOUNT_OF_QUESTIONS,
  amountOfCorrectAnswers,
  hasPassedExamination,
  isMajorMistake,
  isKbcBehaviour,
} from '../_utils/questionHelpers';
import { translations } from '../_translations';
import passedImage from '../_assets/images/passed.svg';
import failedImage from '../_assets/images/failed.svg';
import { Button } from '../_shared';
import { examinationActions } from '../_store/actions';
import { Question } from '../examination/_models/Question';
import { isKbcApp } from '../_utils/initializeHelper';
import './result.scss';

const Result: FC = () => {
  const result = useSelector(examinationSelectors.result);
  const questions = useSelector(examinationSelectors.questions) || [];
  const token = useSelector(authSelectors.token);

  const correctAmountOfAnswers = amountOfCorrectAnswers(result);
  const isPassed = hasPassedExamination(result);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!Object.keys(result).length || !questions.length) return <Redirect to="/" />;

  return (
    <>
      <div className={classnames('result-wrapper', { 'is-kbc-behaviour': isKbcBehaviour(token) })}>
        <div className={classnames('img-wrapper', { isWrong: !isPassed })}>
          <div className="info">
            <h3>{`${correctAmountOfAnswers}/${AMOUNT_OF_QUESTIONS}`}</h3>
            <p>{translations.getLabel(isPassed ? 'RESULT.PASSED' : 'RESULT.FAILED')}</p>
          </div>
          <img alt={translations.getLabel('RESULT.ALT')} src={isPassed ? passedImage : failedImage} />
        </div>
        <div className="results">
          <h2>{translations.getLabel('RESULT.TITLE')}</h2>
          <div className="questions-wrapper">
            {questions.map((question: Question, index: number) => {
              const givenAnswer = result[question.question_id];
              const isWrong = !givenAnswer || !givenAnswer.correct_ind;
              return (
                <p
                  className={classnames('question', {
                    isMajorMistake: isWrong && isMajorMistake(question),
                    isWrong,
                  })}
                  key={question.question_id}
                  onClick={() => dispatch(new examinationActions.ShowResultQuestion({ question }))}
                >
                  {index + 1}
                </p>
              );
            })}
          </div>
          <p>{translations.getLabel('RESULT.INFO')}</p>
          <Button className="reversed" href={isKbcApp() ? '/start' : '/publicity'}>
            {translations.getLabel('BUTTONS.AGAIN')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Result;
