import React from 'react';

import { translations } from '../_translations';
import { Button } from '../_shared';
import publicity from '../_assets/images/publicity.jpg';
import './publicity.scss';

const Publicity = () => (
  <div className="publicity-wrapper">
    <img alt={translations.getLabel('PUBLICITY.IMG_ALT')} className="img" src={publicity} />
    <div className="info-wrapper">
      <h2>{translations.getLabel('PUBLICITY.TITLE')}</h2>
      <p>{translations.getLabel('PUBLICITY.INFO_ONE')}</p>
      <p>{translations.getLabel('PUBLICITY.INFO_TWO')}</p>
      <Button className="continue" href="/">
        {translations.getLabel('BUTTONS.CONTINUE')}
      </Button>
      <a className="continue" href="https://booking.vabrijschool.be/" rel="noopener noreferrer" target="_blank">
        {translations.getLabel('BUTTONS.BOOK_EDUCATION')}
      </a>
    </div>
  </div>
);

export default Publicity;
