import { ExaminationActionType, ExaminationAction } from './actions';
import { Question } from '../_models/Question';
import { selectExampleQuestion, selectExaminationQuestions, selectNextQuestion } from '../../_utils/questionHelpers';
import { Answer } from '../_models/Answer';

export type ExaminationState = {
  currentQuestion?: Question;
  exampleQuestion?: Question;
  questions: Question[];
  result: Record<string, Answer>;
};

const initialState: ExaminationState = {
  currentQuestion: null,
  questions: [],
  result: {},
};

export default function reducer(state = initialState, action: ExaminationAction): ExaminationState {
  switch (action.type) {
    case ExaminationActionType.SelectNextQuestion:
      return {
        ...state,
        currentQuestion: selectNextQuestion(state.currentQuestion?.question_id, state.questions),
      };
    case ExaminationActionType.ShowNextResultQuestion: {
      const currentIndex = state.questions.indexOf(state.currentQuestion);
      return {
        ...state,
        currentQuestion: state.questions[currentIndex + 1],
      };
    }
    case ExaminationActionType.ShowPreviousResultQuestion: {
      const currentIndex = state.questions.indexOf(state.currentQuestion);
      return {
        ...state,
        currentQuestion: state.questions[currentIndex - 1],
      };
    }
    case ExaminationActionType.SetAnswer:
      return {
        ...state,
        result: { ...state.result, [action.payload.questionId]: action.payload.answer },
      };
    case ExaminationActionType.StartExamination: {
      const questions = selectExaminationQuestions();
      return {
        ...state,
        questions,
        result: questions.reduce((acc, question) => ({ ...acc, [question.question_id]: null }), {}),
      };
    }
    case ExaminationActionType.StartExampleQuestion:
      return {
        ...initialState,
        exampleQuestion: selectExampleQuestion(),
      };
    case ExaminationActionType.ShowResultQuestion:
      return {
        ...state,
        currentQuestion: action.payload.question,
      };
    case ExaminationActionType.StopExamination:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
