import React, { ReactNode, FC } from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';

import './pinch.scss';

type Props = {
  children: ReactNode;
  questionId?: number;
};

const Pinch: FC<Props> = ({ children, questionId }) => {
  return (
    <div className="pinch-zoom-wrapper" key={questionId}>
      <PinchZoomPan maxScale={3} position="center" zoomButtons={false}>
        {children}
      </PinchZoomPan>
    </div>
  );
};

export default Pinch;
